import { useApolloClient } from "@apollo/client";
import { CardContainer, MenuButton, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Grid, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik } from "formik";
import { uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  DefineItemProps2Entry,
  DocType,
} from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { ViewMode, ViewModeMenuItem } from "../../../commons/ViewModeMenuItem";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { PropertyField } from "../../doc-items/PropertyField";
import { PropertyAddModal } from "../../doc-items/modals/PropertyAddModal";
import { LabeledProductPropertiesValue } from "../../item-properties/LabeledProductPropertiesValue";
import {
  PimProductPropertyFragment,
  ProductOverview__ProductFragment,
  usePimUpdateProductMutation,
} from "../Product.generated";
import { pimProductPropertyToInput } from "../helper";
import { ProductExtractPropertiesModal } from "../modals/ProductExtractPropertiesModal";

interface FormValues {
  properties: PimProductPropertyFragment[];
}

interface Props {
  product: ProductOverview__ProductFragment;
  isEditable?: boolean;
  refetchQueries?: string[];
  docType: DocType | null;
}

export const ProductOverviewPropertyBox = ({
  docType,
  product,
  isEditable,
  refetchQueries,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate([
    "Global",
    "ProductOverview",
    "ItemPropertyField",
  ]); // TODO: edit namespace

  const viewer = useUserData().currentUser;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const [modifyProduct] = usePimUpdateProductMutation({ client });

  const initialValues: FormValues = {
    properties: product.organisationProperties,
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyProduct({
        variables: {
          input: {
            productArticleNumber: product.articleNumber,
            productSupplierId: product.supplierId,
            values: {
              properties: values.properties.map(pimProductPropertyToInput),
            },
          },
        },
        refetchQueries,
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [viewMode, setViewMode] = React.useState<ViewMode>("visibility");

  return (
    <Formik<FormValues>
      validationSchema={Yup.object().shape({
        properties: Yup.mixed(),
      })}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => {
        const fieldName = "properties" as const;
        return (
          <CardContainer
            title={t("Properties", {
              ns: "Global",
            })}
            isExpandable
            isInitiallyClosed={false}
            itemCount={product.organisationProperties.length}
            ActionButton={
              isEditable ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <ModalOpenButton
                    Modal={PropertyAddModal}
                    modalProps={{
                      docType,
                      showQuestionControl: false,
                      handleComplete: async property => {
                        const newProperty =
                          propertyEntryToPimProperty(property);
                        if (newProperty) {
                          if (
                            values.properties.find(
                              p => p.prop2.key === newProperty.prop2.key
                            )
                          ) {
                            throw new Error(
                              t(
                                "A property already exists with this name. Please enter another name",
                                {
                                  ns: "QuoteItem",
                                }
                              )
                            );
                          }
                          setFieldValue(fieldName, [
                            ...values.properties,
                            newProperty,
                          ]);
                        }
                      },
                    }}
                  >
                    <IconButton size="small" color="primary">
                      <AddIcon />
                    </IconButton>
                  </ModalOpenButton>
                  {!viewer?.organisation.isSupplierOrganisation && (
                    <IconButton
                      color="primary"
                      onClick={() => setIsOpen(true)}
                      size="small"
                    >
                      <FindInPageIcon />
                      <ProductExtractPropertiesModal
                        isOpen={isOpen}
                        title={t("Extracted product properties", {
                          ns: "ProductOverview",
                        })}
                        productArticleNumber={product.articleNumber}
                        productSupplierId={product.supplierId}
                        currentProperties={uniqBy(
                          product.organisationProperties.concat(
                            product.supplierProductProperties
                          ),
                          p => p.prop2.key
                        ).map(p => p.prop2)}
                        refetchQueries={refetchQueries}
                        handleClose={() => setIsOpen(false)}
                        handleComplete={async () => {
                          setIsOpen(false);
                        }}
                      />
                    </IconButton>
                  )}
                  <MenuButton>
                    <ViewModeMenuItem
                      viewMode={viewMode}
                      onViewModeChange={setViewMode}
                      allowedModes={["visibility", "delete"]}
                    />
                  </MenuButton>
                </Stack>
              ) : undefined
            }
          >
            {isEditable ? (
              <Form>
                <Stack direction={"column"} p={1} spacing={1}>
                  <FieldArray
                    name={fieldName}
                    render={arrayHelpers => (
                      <Grid container spacing={1} columns={2}>
                        {values[fieldName].map((property, i) => (
                          <Grid key={property.prop2.key} item xs={1}>
                            <PropertyField
                              disableExpressions
                              docId=""
                              itemId=""
                              projectId=""
                              property={property.prop2}
                              setPropertyValue={property => {
                                if (property.bool) {
                                  setFieldValue(
                                    `${fieldName}.[${i}].prop2.valueBool`,
                                    property.bool.valueBool
                                  );
                                } else if (property.number) {
                                  setFieldValue(
                                    `${fieldName}.[${i}].prop2.valueNumber`,
                                    property.number.valueNumber
                                  );
                                } else if (property.text) {
                                  setFieldValue(
                                    `${fieldName}.[${i}].prop2.valueText`,
                                    property.text.valueText
                                  );
                                } else {
                                  throw new Error(
                                    "Property kind not supported"
                                  );
                                }
                              }}
                              setVisibility={
                                viewMode === "visibility"
                                  ? (key, isVisible) => {
                                      setFieldValue(
                                        `${fieldName}.[${i}].prop2.clientVisibility`,
                                        isVisible
                                      );
                                    }
                                  : undefined
                              }
                              handleDelete={
                                viewMode === "delete"
                                  ? () => {
                                      arrayHelpers.remove(i);
                                    }
                                  : undefined
                              }
                              readOnly={false}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  />
                  <AutoSave
                    enableReinitialize
                    initialValues={initialValues}
                    filterValues={values => ({
                      properties: values.properties,
                    })}
                  />
                </Stack>
              </Form>
            ) : (
              <Stack direction="column" p={1} spacing={1}>
                <LabeledProductPropertiesValue
                  properties={product.organisationProperties.map(p => p.prop2)}
                  viewerIsContractor={true} // TODO
                />
              </Stack>
            )}
          </CardContainer>
        );
      }}
    </Formik>
  );
};

export function propertyEntryToPimProperty(
  property: DefineItemProps2Entry
): PimProductPropertyFragment | null {
  return property.bool
    ? {
        prop2: {
          ...property.bool,
          __typename: "Props2Bool" as const,
        },
        pimProperty: {
          __typename: "PimBoolProperty" as const,
        },
        __typename: "PimProductPropertyBool" as const,
      }
    : property.text
      ? {
          prop2: {
            ...property.text,
            allowedValuesText: property.text.allowedValuesText.map(v => ({
              ...v,
              media: null,
              __typename: "Props2AllowedValuesText" as const,
            })),
            __typename: "Props2Text" as const,
          },
          pimProperty: {
            __typename: "PimTextProperty" as const,
          },
          __typename: "PimProductPropertyText" as const,
        }
      : property.number
        ? {
            prop2: {
              ...property.number,
              allowedValuesNumber: property.number.allowedValuesNumber.map(
                v => ({
                  ...v,
                  media: null,
                  __typename: "Props2AllowedValuesNumber" as const,
                })
              ),
              range: {
                ...property.number.range,
                __typename: "Props2NumberRange" as const,
              },
              __typename: "Props2Number",
            },
            pimProperty: {
              __typename: "PimNumberProperty" as const,
            },
            __typename: "PimProductPropertyNumber" as const,
          }
        : null;
}
