import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, Modal, useScreenWidth } from "@msys/ui";
import { Button, DialogActions, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { LabeledPropertiesValue } from "../item-properties/LabeledPropertiesValue";
import { useTemplateTypeOverviewModalQuery } from "./TemplateTypeOverviewModal.generated";

interface Props {
  templateTypeId: string;
  headerActions?: React.ReactNode;
  handleClose: () => void;
  dialogActionButton?: React.ReactNode;
  canEdit?: boolean;
}

export const TemplateTypeOverviewModal = ({
  templateTypeId,
  handleClose,
  headerActions,
  dialogActionButton,
}: Props) => {
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Global"]);

  const client = useApolloClient();
  const query = useTemplateTypeOverviewModalQuery({
    client,
    variables: {
      id: templateTypeId,
    },
  });

  const templateType = getDataOrNull(query?.data?.templateType);

  return (
    <Modal
      title={templateType?.title}
      handleClose={handleClose}
      maxWidth="md"
      isLoading={query.loading}
      headerActions={headerActions}
      dialogActions={
        <DialogActions>
          <Stack
            direction={isMinTablet ? "row" : "column"}
            spacing={2}
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              alignSelf={isMinTablet ? undefined : "flex-end"}
            >
              <Button onClick={handleClose} color="primary" variant="text">
                {t("Close", { ns: "Global" })}
              </Button>
              {dialogActionButton}
            </Stack>
          </Stack>
        </DialogActions>
      }
      notInStack
    >
      {templateType && (
        <CardContainer
          title={t("Properties", { ns: "Global" })}
          isExpandable
          isInitiallyClosed={false}
          itemCount={templateType.props2.length}
        >
          <Stack direction="column" p={1} spacing={1}>
            <LabeledPropertiesValue
              properties={templateType.props2}
              showAllowedValues
            />
          </Stack>
        </CardContainer>
      )}
    </Modal>
  );
};
