import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  ErrorMessage,
  FormattedPrice,
  GridColDef,
  LoadingSpinner,
  StateStore,
  useFormatting,
} from "@msys/ui";
import {
  Button,
  Chip,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  OrganisationProjectsSortBy,
  OrganisationProjectsSorting,
  ProjectFilterKind,
} from "../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../auth/useUserData";
import { EntityNumber } from "../../commons/EntityNumber";
import { CollectionView } from "../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants";
import { getAddressLabel } from "../addresses/helpers";
import { getProjectCrmLinksLabel } from "../contact-links/helper";
import { useRelationshipRoles } from "../contact-links/useRelationshipRoles";
import { useGetCustomFieldValueAsDisplayContent } from "../custom-fields/CustomFieldsBox";
import { useCustomFieldConfig } from "../custom-fields/useCustomFieldConfig";
import { useCategories } from "../skill-categories/useCategories";
import { getPersonLabel } from "../users/helpers";
import { ProjectStatusBadge } from "./badges/ProjectStatusBadge";
import {
  ProjectListItem,
  ProjectListItemDisplayConfig,
} from "./components/ProjectListItem";
import { ProjectSourceCreatedBy } from "./components/ProjectSourceCreatedBy";
import { ProjectSourceEntity } from "./components/ProjectSourceEntity";
import { ProjectAssigneeEditButton } from "./ProjectAssigneeEditButton";
import { ProjectPhaseChip } from "./ProjectPhaseChip";
import {
  ProjectsList_ProjectFragment,
  ProjectsListQueryVariables,
  useProjectsListQuery,
} from "./ProjectsList.generated";
import { TicketUrgentBadge } from "./TicketStatusBadge";
import { ProjectSourceFilterType } from "./useProjectSources";

export const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];
export type Filters = Omit<
  ProjectsListQueryVariables,
  "offset" | "limit" | "sorting" | "filterBySources"
> & {
  kind?: ProjectFilterKind[];
  sources?: ProjectSourceFilterType[] | undefined | null;
};

interface Props {
  sorting: OrganisationProjectsSorting[];
  setSorting: React.Dispatch<
    React.SetStateAction<OrganisationProjectsSorting[]>
  >;
  activeView: CollectionView;
  pathToPage: string;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  showStatus?: boolean;
  showPhase?: boolean;
  stateStore: StateStore;
  variables: ProjectsListQueryVariables;
  displayConfig: ProjectListItemDisplayConfig;
}

export function ProjectsList({
  sorting,
  setSorting,
  activeView,
  pathToPage,
  paginationModel,
  setPaginationModel,
  showStatus = true,
  showPhase = false,
  stateStore,
  variables,
  displayConfig,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslate(["Projects", "Tickets", "Global"]);
  const viewer = useUserData().currentUser!;
  const { getFormattedDate, getFormattedDateTime } = useFormatting();

  const { getRoleLabel } = useRelationshipRoles();
  const { categoryLabels } = useCategories();

  const getCustomFieldValueAsDisplayContent =
    useGetCustomFieldValueAsDisplayContent();
  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  const client = useApolloClient();
  const query = useProjectsListQuery({
    client,
    variables,
  });
  const projects =
    getDataOrNull(
      query.data?.projects ?? query.previousData?.projects
    )?.edges?.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull(query.data?.projects ?? query.previousData?.projects)
      ?.totalCount ?? 0;
  const phases = query.data?.organisationSettings.projectPhases;

  const columns = React.useMemo(
    (): GridColDef<ProjectsList_ProjectFragment>[] => [
      {
        field: "number",
        headerName: t("No.", { ns: "Global" }),
        minWidth: 60,
        flex: 0.75,
        renderCell: ({ row: project }) => (
          <EntityNumber noLabel number={project.number} />
        ),
      },
      {
        field: "phase",
        headerName: t("Phase", { ns: "Opportunities" }),
        editable: false,
        sortable: true,
        flex: 1,
        minWidth: 100,
        renderCell: ({ row: project }) => (
          <ProjectPhaseChip
            projectId={project.id}
            currentState={project.state}
            currentPhase={project.phase}
            availablePhases={phases}
          />
        ),
      },
      {
        field: "title",
        headerName: t("Title", { ns: "Projects" }),
        minWidth: 100,
        flex: 3,
        renderCell: ({ row: project }) => (
          <>
            {project.title}
            {project.urgent && (
              <span
                style={{
                  display: "inline-flex",
                  marginLeft: "8px",
                  verticalAlign: "middle",
                }}
              >
                <TicketUrgentBadge small />
              </span>
            )}
          </>
        ),
      },
      {
        field: "organisationName",
        headerName: viewer.organisation.isClientOrganisation
          ? t("Organisation", {
              ns: "Projects",
            })
          : t("Client", {
              ns: "Projects",
            }),
        minWidth: 100,
        flex: 2,
        valueGetter: ({ row: opportunity }) =>
          [
            opportunity.crmOrganisation?.title,
            `${opportunity.crmOrganisation?.contactPerson?.firstname ?? ""} ${
              opportunity.crmOrganisation?.contactPerson?.familyname ?? ""
            }`.trim(),
          ]
            .filter(Boolean)
            .join(" - "),
        renderCell: ({ row: opportunity }) =>
          [
            opportunity.crmOrganisation?.title,
            `${opportunity.crmOrganisation?.contactPerson?.firstname ?? ""} ${
              opportunity.crmOrganisation?.contactPerson?.familyname ?? ""
            }`.trim(),
          ]
            .filter(Boolean)
            .join(" - "),
      },
      {
        field: "organisationEmail",
        headerName: t("Email", { ns: "Global" }),
        editable: false,
        sortable: true,
        flex: 2,
        minWidth: 100,
        valueGetter: ({ row: project }) =>
          project.crmOrganisation?.email ?? "–",
      },
      {
        field: "organisationPhone",
        headerName: t("Phone", { ns: "Global" }),
        editable: false,
        sortable: false,
        flex: 2,
        minWidth: 100,
        valueGetter: ({ row: project }) =>
          project.crmOrganisation?.phones?.[0]?.number ?? "–",
      },
      {
        field: "buildingStreet",
        headerName: t("Address", {
          ns: "Projects",
        }),
        minWidth: 100,
        flex: 2,
        valueGetter: ({ row: project }) => getAddressLabel(project.address),
        renderCell: ({ row: project }) =>
          project.address
            ? getAddressLabel(project.address)
            : t("Not set", { ns: "Global" }),
      },
      {
        field: "assignee",
        headerName: t("Assignee", {
          ns: "ProjectMembers",
        }),
        editable: false,
        sortable: true,
        flex: 2,
        minWidth: 100,
        valueGetter: ({ row: opportunity }) =>
          opportunity.assignee
            ? getPersonLabel(opportunity.assignee.user, false)
            : "–",
        renderCell: ({ row: project }) => (
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_PROJECT"
            project={project}
            otherwise={
              <ProjectAssigneeEditButton
                assignee={project.assignee ?? null}
                projectId={project.id}
                projectTitle={project.title}
                readOnly={true}
                type="button"
              />
            }
          >
            <ProjectAssigneeEditButton
              assignee={project.assignee ?? null}
              projectId={project.id}
              projectTitle={project.title}
              type="button"
            />
          </RestrictedByProjectPermissionWithDebug>
        ),
      },
      {
        field: "budget",
        headerName: t("Budget", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        sortable: true,
        align: "right",
        headerAlign: "right",
        valueGetter: ({ row: opportunity }) => opportunity.budget?.amount ?? 0,
        renderCell: ({ row: project }) => (
          <RestrictedByProjectPermissionWithDebug
            project={project}
            permission="READ_QUOTES"
          >
            <Typography
              component="span"
              align="right"
              variant="body2"
              style={{ fontWeight: 500 }}
            >
              <FormattedPrice value={project.budget} />
            </Typography>
          </RestrictedByProjectPermissionWithDebug>
        ),
      },
      {
        field: "source",
        headerName: t("Source", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        hideable: true,
        sortable: false,
        renderCell: ({ row: project }) => (
          <ProjectSourceEntity
            projectSource={project.source}
            projectId={project.id}
          />
        ),
      },
      {
        field: "state",
        headerName: t("Status", { ns: "Projects" }),
        minWidth: 80,
        sortable: true,
        flex: 1,
        renderCell: ({ row: project }) => (
          <ProjectStatusBadge projectState={project.state} />
        ),
      },
      {
        field: "crmLinks",
        headerName: t("Other contacts", { ns: "Projects" }),
        minWidth: 100,
        sortable: false,
        flex: 1,
        renderCell: ({ row: project }) =>
          getProjectCrmLinksLabel(project, getRoleLabel),
      },
      {
        field: "category",
        headerName: t("Category", { ns: "Categories" }),
        editable: false,
        sortable: false,
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row: opportunity }) => opportunity.categories,
        renderCell: ({ row: opportunity }) =>
          opportunity.categories.map(category => (
            <Chip
              label={categoryLabels[category]}
              size="small"
              color="primary"
            />
          )),
      },
      {
        field: "createdBy",
        headerName: t("Created by", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        sortable: true,
        renderCell: ({ row: project }) => (
          <ProjectSourceCreatedBy projectSource={project.source} />
        ),
      },
      {
        field: "createdAt",
        headerName: t("Created at", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row: project }) =>
          project.createdAt ? new Date(project.createdAt) : null,
        renderCell: ({ row: project }) =>
          project.createdAt ? getFormattedDateTime(project.createdAt) : "-",
      },
      {
        field: "deadline",
        headerName: t("Deadline", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        renderCell: ({ row: project }) =>
          project.deadline ? (
            <Typography
              color={project.overdue ? "error" : undefined}
              component="span"
              variant="body2"
            >
              {getFormattedDate(project.deadline)}
            </Typography>
          ) : (
            "–"
          ),
      },
      {
        field: "earliestStart",
        headerName: t("Earliest start", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        renderCell: ({ row: project }) =>
          project.earliestStart ? getFormattedDate(project.earliestStart) : "–",
      },
      ...(viewer.organisation.capabilities.includes("QUOTING")
        ? [
            {
              field: "leadId",
              headerName: t("Lead ID", { ns: "Projects" }),
              flex: 1,
              hideable: true,
              sortable: false,
              valueGetter: ({ row: opportunity }) => opportunity.leadId ?? "–",
            } as GridColDef<ProjectsList_ProjectFragment>,
          ]
        : []),
      ...customFieldConfigs
        .filter(e => e.showAsColumnInList)
        .map(e => {
          return {
            field: `custom:${e.key}`,
            headerName: e.key,
            minWidth: 100,
            sortable: false,
            flex: 1,
            renderCell: ({ row: project }) => {
              const field = project.customFields.find(f => f.key === e.key);
              return getCustomFieldValueAsDisplayContent(
                field
                  ? {
                      dataType: field.dataType,
                      key: field.key,
                      value: JSON.parse(field.value),
                    }
                  : undefined
              );
            },
          } as GridColDef<ProjectsList_ProjectFragment>;
        }),
      ...(viewer.organisation.capabilities.includes("PLANNING")
        ? [
            {
              field: "plannedUsers",
              headerName: t("Resources", { ns: "Projects" }),
              minWidth: 100,
              flex: 2,
              sortable: false,
              renderCell: ({ row: ticket }) => {
                if (!ticket.plannedUsers || ticket.plannedUsers.length === 0) {
                  return (
                    <Button
                      size="extra-small"
                      color="primary"
                      variant="outlined"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.stopPropagation();
                      }}
                      component={Link}
                      to={{
                        pathname: `/planning/projects/${ticket.id}`,
                        search: new URLSearchParams({
                          r: location.pathname + location.search,
                        }).toString(),
                      }}
                    >
                      {t("Plan", {
                        ns: "Projects",
                      })}
                    </Button>
                  );
                }

                return (
                  <span>
                    {ticket.plannedUsers
                      .map(assignee =>
                        assignee ? getPersonLabel(assignee, false) : null
                      )
                      .filter(notNull)
                      .join(", ")}
                  </span>
                );
              },
            } as GridColDef<ProjectsList_ProjectFragment>,
          ]
        : []),
    ],
    [
      t,
      viewer.organisation.isClientOrganisation,
      viewer.organisation.capabilities,
      customFieldConfigs,
      phases,
      getRoleLabel,
      categoryLabels,
      getFormattedDateTime,
      getFormattedDate,
      getCustomFieldValueAsDisplayContent,
      location.pathname,
      location.search,
    ]
  );

  switch (activeView) {
    case "table": {
      return (
        <DataGrid
          stateStore={stateStore}
          loading={query.loading}
          hideFooter={totalCount === 0}
          columns={columns}
          rows={projects}
          sortModel={sorting.map(s => ({
            field: s.column,
            sort: s.direction,
          }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as OrganisationProjectsSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          onRowClick={({ row: project }) => {
            navigate(`/projects/${project.id}/overview`);
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
          initialState={{
            columns: {
              columnVisibilityModel: {
                organisationEmail: false,
                organisationPhone: false,
                assignee: false,
                source: false,
                earliestStart: false,
                ...(!showStatus ? { status: false } : {}),
              },
            },
          }}
        />
      );
    }
    case "list": {
      if (query.loading) return <LoadingSpinner />;
      if (totalCount === 0)
        return (
          <ErrorMessage
            message={t("There are no items to display", {
              ns: "Global",
            })}
          />
        );
      return (
        <Stack direction="column" spacing={1}>
          {projects.map(project => (
            <CardItem
              key={project.id}
              //@ts-ignore
              component={Link}
              to={`/projects/${project.id}/overview`}
            >
              <ProjectListItem
                project={project}
                showStatus={showStatus}
                showPhase={showPhase}
                availablePhases={phases}
                displayConfig={displayConfig}
              />
            </CardItem>
          ))}
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", { ns: "Global" })}
          />
        </Stack>
      );
    }
    default:
      return null;
  }
}
