/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectTicketUnplannedAlertFragment = { __typename: 'Project', id: string, ticket: boolean, state: Types.ProjectStateMachineStatus, planned: boolean };

export const ProjectTicketUnplannedAlertFragmentDoc = gql`
    fragment ProjectTicketUnplannedAlert on Project {
  id
  ticket
  state
  planned
}
    `;