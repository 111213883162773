import { CardContainer } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { LabeledPropertiesValue } from "../item-properties/LabeledPropertiesValue";
import { TemplateTypeOverviewPropertyBox_TemplateTypeFragment } from "./TemplateTypeOverviewPropertyBox.generated";

interface Props {
  templateType: TemplateTypeOverviewPropertyBox_TemplateTypeFragment;
}

export const TemplateTypeOverviewPropertyBox = ({ templateType }: Props) => {
  const { t } = useTranslate(["Global", "TemplateTypeOverview"]);

  return (
    <CardContainer
      title={t("Properties", {
        ns: "Global",
      })}
      isExpandable
      isInitiallyClosed={false}
      itemCount={templateType.props2.length}
      // ActionButton={isEditable ? actionButtons : undefined}
    >
      <Stack direction="column" p={1} spacing={1}>
        <LabeledPropertiesValue
          properties={templateType.props2}
          hideVisibility
          showAllowedValues
        />
      </Stack>
    </CardContainer>
  );
};
