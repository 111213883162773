import { CardContainer } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { LabeledProductPropertiesValue } from "../../item-properties/LabeledProductPropertiesValue";
import {
  PimProductPropertyFragment,
  ProductOverview__ProductFragment,
} from "../Product.generated";

interface Props {
  product: ProductOverview__ProductFragment;
  viewerIsContractor: boolean;
}

export const ProductOverviewMergedPropertiesBox = ({
  product,
  viewerIsContractor,
}: Props) => {
  const { t } = useTranslate(["Global"]);

  const properties = product.organisationProperties.concat(
    product.supplierProductProperties
  );

  return (
    <CardContainer
      title={t("Properties", {
        ns: "Global",
      })}
      isExpandable
      isInitiallyClosed={false}
      itemCount={properties.length}
    >
      <Stack direction="column" p={1} spacing={1}>
        <LabeledProductPropertiesValue
          properties={properties.map(p => p.prop2)}
          viewerIsContractor={viewerIsContractor}
        />
      </Stack>
    </CardContainer>
  );
};
