import { assertNever } from "@msys/common";
import {
  Autocomplete,
  defaultIsEqualArray,
  FormattedFloatInput,
  LabeledValue,
  LabeledValueWithButton,
  ModalOpenButton,
  SelectMultiple,
  useFormatting,
  useSyncState,
  VisibilityButton,
} from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import FunctionsIcon from "@mui/icons-material/Functions";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import {
  EnterItemProps2ValueEntry,
  Props2,
  Props2Bool,
  Props2BoolComputed,
  Props2Number,
  Props2NumberArray,
  Props2NumberArrayComputed,
  Props2NumberComputed,
  Props2Text,
  Props2TextArray,
  Props2TextArrayComputed,
  Props2TextComputed,
} from "../../../clients/graphqlTypes";
import { renderValueWithAllowedValues } from "../item-properties/LabeledPropertiesValue";
import { notSetSymbol } from "./helpers";
import { PropertyExpressionModal } from "./modals/PropertyExpressionModal";
import {
  getEnhancedPropertyLabel,
  getPropValueWithUnitAndAllowedValues,
  stringArrayToNumberArray,
} from "./properties";
import { usePropertyHelpers } from "./usePropertyHelpers";

interface ComputedPropertyFieldProps<Property> {
  projectId: string | null;
  docId: string;
  itemId: string;
  property: Property;
  readOnly?: boolean;
  getPropertyDisplayValue: (property: Props2) => {
    displayedValue: string;
    alternativeAllowedValues: string;
    unit: string;
  };
}

interface PropertyFieldProps<Property>
  extends ComputedPropertyFieldProps<Property> {
  property: Property;
  setPropertyValue: (
    property: EnterItemProps2ValueEntry
  ) => Promise<void> | void;
  expressionButtonRef: React.RefObject<HTMLButtonElement>;
  isEditing: boolean;
  setIsEditing: (newValue: boolean) => void;
}

interface Props<Property>
  extends Omit<
    PropertyFieldProps<Property>,
    | "expressionButtonRef"
    | "isEditing"
    | "setIsEditing"
    | "getPropertyDisplayValue"
  > {
  setVisibility?: (key: string, isVisible: boolean) => Promise<void> | void;
  handleDelete?: (key: string) => Promise<void> | void;
  disableExpressions?: boolean;
  spacing?: number;
}

export function PropertyField({
  property,
  setPropertyValue,
  readOnly,
  setVisibility,
  handleDelete,
  disableExpressions,
  spacing = 1,
  ...props
}: Props<Props2>) {
  const areExpressionsEnabled = !disableExpressions;
  const expressionButtonRef = React.useRef<HTMLButtonElement>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const { getUnitLabel, getBoolLabel, getNumberLabel } = usePropertyHelpers();
  const getPropertyDisplayValue = React.useCallback(
    (property: Props2) =>
      getPropValueWithUnitAndAllowedValues(
        property,
        getUnitLabel,
        getBoolLabel,
        getNumberLabel,
        notSetSymbol
      ),
    [getUnitLabel, getBoolLabel, getNumberLabel]
  );

  return (
    <Stack
      direction={"row"}
      spacing={spacing}
      alignItems={"center"}
      justifyContent={"space-between"}
      width="100%"
    >
      <Box flex={1} minWidth={0}>
        <PropertyFieldInternal
          property={property}
          setPropertyValue={setPropertyValue}
          readOnly={readOnly}
          expressionButtonRef={expressionButtonRef}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          getPropertyDisplayValue={getPropertyDisplayValue}
          {...props}
        />
      </Box>
      {isEditing && areExpressionsEnabled ? (
        <ModalOpenButton
          Modal={PropertyExpressionModal}
          modalProps={{
            ...props,
            fieldName: property.key,
            expression: undefined,
          }}
          ref={expressionButtonRef}
          onCloseCallback={() => {
            setIsEditing(false);
          }}
        >
          <ExpressionButton />
        </ModalOpenButton>
      ) : (
        (setVisibility || handleDelete) && (
          <Box
            display="flex"
            alignSelf="flex-start"
            alignItems="center"
            height="48px"
          >
            {setVisibility && (
              <VisibilityButton
                isVisible={property.clientVisibility}
                onChange={isVisible => {
                  setVisibility(property.key, isVisible);
                }}
                disabled={readOnly}
              />
            )}
            {handleDelete && (
              <IconButton
                size="small"
                color={"secondary"}
                onClick={() => handleDelete(property.key)}
                disabled={readOnly}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )
      )}
    </Stack>
  );
}

function PropertyFieldInternal({
  property,
  setPropertyValue,
  readOnly,
  ...props
}: PropertyFieldProps<Props2>) {
  switch (property.__typename) {
    case "Props2Bool": {
      return (
        <BooleanPropertyField
          property={property}
          setPropertyValue={setPropertyValue}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2BoolComputed": {
      return (
        <BooleanComputedPropertyField
          property={property}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2Number": {
      return (
        <NumberPropertyField
          property={property}
          setPropertyValue={setPropertyValue}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2NumberComputed": {
      return (
        <NumberComputedPropertyField
          property={property}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2NumberArray": {
      return (
        <NumberArrayPropertyField
          property={property}
          setPropertyValue={setPropertyValue}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2NumberArrayComputed": {
      return (
        <NumberArrayComputedPropertyField
          property={property}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2Text": {
      return (
        <TextPropertyField
          property={property}
          setPropertyValue={setPropertyValue}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2TextComputed": {
      return (
        <TextComputedPropertyField
          property={property}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2TextArray": {
      return (
        <TextArrayPropertyField
          property={property}
          setPropertyValue={setPropertyValue}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    case "Props2TextArrayComputed": {
      return (
        <TextArrayComputedPropertyField
          property={property}
          readOnly={readOnly}
          {...props}
        />
      );
    }
    default: {
      assertNever(property);
    }
  }
}

function BooleanPropertyField({
  property,
  setPropertyValue,
  readOnly,
  expressionButtonRef,
  isEditing,
  setIsEditing,
  getPropertyDisplayValue,
}: PropertyFieldProps<Props2Bool>) {
  const latestSavedValue = React.useRef<boolean | null | undefined>(undefined);

  const [value, setValue] = useSyncState<boolean | null>(
    property.valueBool ?? null,
    latestSavedValue
  );
  const { getBoolLabel } = usePropertyHelpers();

  const options = [true, false].map(value => ({
    label: getBoolLabel(value),
    value,
  }));

  const setBooleanPropertyValue = React.useCallback(
    (value: boolean | null) => {
      latestSavedValue.current = value;
      setPropertyValue({
        bool: {
          key: property.key,
          valueBool: value,
        },
      });
    },
    [property.key, setPropertyValue]
  );

  const debouncedSetBooleanPropertyValue = React.useMemo(
    () => debounce(setBooleanPropertyValue, 500),
    [setBooleanPropertyValue]
  );

  const handleBlur = (event: React.FocusEvent) => {
    if (
      (!expressionButtonRef.current ||
        event.relatedTarget !== expressionButtonRef.current) &&
      !isEventRelatedTargetInsideTarget(event.nativeEvent)
    ) {
      setIsEditing(false);
    }
  };

  if (readOnly) {
    return (
      <LabeledValue label={getEnhancedPropertyLabel(property)}>
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValue>
    );
  }

  if (isEditing) {
    return (
      <Autocomplete
        autoFocus
        inputLabel={getEnhancedPropertyLabel(property)}
        options={options}
        getOptionLabel={option => option?.label ?? notSetSymbol}
        value={options.find(option => option.value === value) ?? null}
        onChange={option => {
          const value = option?.value ?? null;
          setValue(value);
          debouncedSetBooleanPropertyValue(value);
        }}
        onBlur={handleBlur}
        TextFieldProps={{ InputLabelProps: { shrink: true } }}
      />
    );
  }

  return (
    <LabeledValueWithButton
      label={getEnhancedPropertyLabel(property)}
      onClick={() => setIsEditing(true)}
    >
      {getPropertyDisplayValue(property).displayedValue}
    </LabeledValueWithButton>
  );
}
function BooleanComputedPropertyField({
  property,
  readOnly,
  getPropertyDisplayValue,
  ...props
}: ComputedPropertyFieldProps<Props2BoolComputed>) {
  if (readOnly) {
    return (
      <LabeledValue
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValue>
    );
  }

  return (
    <ModalOpenButton
      Modal={PropertyExpressionModal}
      modalProps={{
        ...props,
        fieldName: property.key,
        expression: property.expr,
      }}
    >
      <LabeledValueWithButton
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValueWithButton>
    </ModalOpenButton>
  );
}
function NumberPropertyField({
  property,
  setPropertyValue,
  readOnly,
  expressionButtonRef,
  isEditing,
  setIsEditing,
  getPropertyDisplayValue,
}: PropertyFieldProps<Props2Number>) {
  const { getFormattedFloat } = useFormatting();
  const latestSavedValue = React.useRef<number | null | undefined>(undefined);

  const [value, setValue] = useSyncState<number | null>(
    property.valueNumber ?? null,
    latestSavedValue
  );

  const setNumberPropertyValue = React.useCallback(
    (value: number | null) => {
      latestSavedValue.current = value;
      setPropertyValue({
        number: {
          key: property.key,
          valueNumber: value,
        },
      });
    },
    [property.key, setPropertyValue]
  );

  const debouncedSetNumberPropertyValue = React.useMemo(
    () => debounce(setNumberPropertyValue, 500),
    [setNumberPropertyValue]
  );

  const handleBlur = (event: React.FocusEvent) => {
    if (
      (!expressionButtonRef.current ||
        event.relatedTarget !== expressionButtonRef.current) &&
      !isEventRelatedTargetInsideTarget(event.nativeEvent)
    ) {
      setIsEditing(false);
    }
  };

  const options = property.allowedValuesNumber.map(value => ({
    label: getFormattedFloat(value.allowedNumber),
    value: value.allowedNumber,
  }));

  if (readOnly) {
    return (
      <LabeledValue label={getEnhancedPropertyLabel(property)}>
        {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
      </LabeledValue>
    );
  }
  if (isEditing) {
    return property.allowedValuesNumber.length > 0 ? (
      <Autocomplete
        autoFocus
        inputLabel={getEnhancedPropertyLabel(property)}
        options={options}
        getOptionLabel={option => option?.label ?? notSetSymbol}
        value={options.find(option => option.value === value) ?? null}
        onChange={option => {
          const value = option?.value ?? null;
          setValue(value);
          debouncedSetNumberPropertyValue(value);
        }}
        onBlur={handleBlur}
        TextFieldProps={{ InputLabelProps: { shrink: true } }}
      />
    ) : (
      <FormattedFloatInput
        autoFocus
        label={getEnhancedPropertyLabel(property)}
        unit={property.unit ?? undefined}
        value={value}
        min={property.range?.min ?? undefined}
        max={property.range?.max ?? undefined}
        onChange={value => {
          setValue(value);
          debouncedSetNumberPropertyValue(value);
        }}
        onBlur={handleBlur}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  return (
    <LabeledValueWithButton
      label={getEnhancedPropertyLabel(property)}
      onClick={() => setIsEditing(true)}
    >
      {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
    </LabeledValueWithButton>
  );
}
function NumberComputedPropertyField({
  property,
  readOnly,
  getPropertyDisplayValue,
  ...props
}: ComputedPropertyFieldProps<Props2NumberComputed>) {
  if (readOnly) {
    return (
      <LabeledValue
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValue>
    );
  }
  return (
    <ModalOpenButton
      Modal={PropertyExpressionModal}
      modalProps={{
        ...props,
        fieldName: property.key,
        expression: property.expr,
      }}
    >
      <LabeledValueWithButton
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValueWithButton>
    </ModalOpenButton>
  );
}

function NumberArrayPropertyField({
  property,
  readOnly,
  setPropertyValue,
  expressionButtonRef,
  isEditing,
  setIsEditing,
  getPropertyDisplayValue,
}: PropertyFieldProps<Props2NumberArray>) {
  const { getFormattedFloat } = useFormatting();

  const latestSavedValue = React.useRef<number[] | null | undefined>(undefined);

  const [value, setValue] = useSyncState<number[] | null>(
    property.valueNumberArray ?? null,
    latestSavedValue,
    defaultIsEqualArray
  );

  const setNumberArrayPropertyValue = React.useCallback(
    (value: number[] | null) => {
      latestSavedValue.current = value;
      setPropertyValue({
        numberArray: {
          key: property.key,
          valueNumberArray: value,
        },
      });
    },
    [property.key, setPropertyValue]
  );

  const debouncedSetNumberArrayPropertyValue = React.useMemo(
    () => debounce(setNumberArrayPropertyValue, 500),
    [setNumberArrayPropertyValue]
  );

  const handleBlur = (event: React.FocusEvent) => {
    if (
      (!expressionButtonRef.current ||
        event.relatedTarget !== expressionButtonRef.current) &&
      !isEventRelatedTargetInsideTarget(event.nativeEvent)
    ) {
      setIsEditing(false);
    }
  };

  if (readOnly) {
    return (
      <LabeledValue label={getEnhancedPropertyLabel(property)}>
        {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
      </LabeledValue>
    );
  }

  if (isEditing) {
    return property.allowedValuesNumber.length > 0 ? (
      <SelectMultiple
        autoFocus
        label={getEnhancedPropertyLabel(property)}
        options={property.allowedValuesNumber.map(value => ({
          label: getFormattedFloat(value.allowedNumber),
          value: value.allowedNumber,
        }))}
        value={value ?? []}
        onChange={value => {
          setValue(value);
          debouncedSetNumberArrayPropertyValue(value);
        }}
        MenuProps={{ disablePortal: true }}
        onBlur={handleBlur}
      />
    ) : (
      <TextField
        autoFocus
        label={getEnhancedPropertyLabel(property)}
        value={value?.join(",") ?? ""}
        onChange={event => {
          const value = event.target.value;
          const newValue = value
            ? stringArrayToNumberArray(value.split(","))
            : null;
          setValue(newValue);
          debouncedSetNumberArrayPropertyValue(newValue);
        }}
        onBlur={handleBlur}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  return (
    <LabeledValueWithButton
      label={getEnhancedPropertyLabel(property)}
      onClick={() => setIsEditing(true)}
    >
      {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
    </LabeledValueWithButton>
  );
}

function NumberArrayComputedPropertyField({
  property,
  readOnly,
  getPropertyDisplayValue,
  ...props
}: ComputedPropertyFieldProps<Props2NumberArrayComputed>) {
  if (readOnly) {
    return (
      <LabeledValue
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValue>
    );
  }
  return (
    <ModalOpenButton
      Modal={PropertyExpressionModal}
      modalProps={{
        ...props,
        fieldName: property.key,
        expression: property.expr,
      }}
    >
      <LabeledValueWithButton
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValueWithButton>
    </ModalOpenButton>
  );
}

function TextPropertyField({
  property,
  setPropertyValue,
  readOnly,
  expressionButtonRef,
  isEditing,
  setIsEditing,
  getPropertyDisplayValue,
}: PropertyFieldProps<Props2Text>) {
  const latestSavedValue = React.useRef<string | undefined>(undefined);

  const [value, setValue] = useSyncState<string>(
    property.valueText ?? "",
    latestSavedValue
  );

  const setTextPropertyValue = React.useCallback(
    (value: string) => {
      latestSavedValue.current = value;
      setPropertyValue({
        text: {
          key: property.key,
          valueText: value.length ? value : null,
        },
      });
    },
    [property.key, setPropertyValue]
  );

  const debouncedSetTextPropertyValue = React.useMemo(
    () => debounce(setTextPropertyValue, 500),
    [setTextPropertyValue]
  );

  const handleBlur = (event: React.FocusEvent) => {
    if (
      (!expressionButtonRef.current ||
        event.relatedTarget !== expressionButtonRef.current) &&
      !isEventRelatedTargetInsideTarget(event.nativeEvent)
    ) {
      setIsEditing(false);
    }
  };

  const options = property.allowedValuesText.map(value => ({
    label: value.allowedText,
    value: value.allowedText,
  }));

  if (readOnly) {
    return (
      <LabeledValue label={getEnhancedPropertyLabel(property)}>
        {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
      </LabeledValue>
    );
  }

  if (isEditing) {
    return property.allowedValuesText.length > 0 ? (
      <Autocomplete
        autoFocus
        inputLabel={getEnhancedPropertyLabel(property)}
        options={options}
        getOptionLabel={option => option.label ?? notSetSymbol}
        value={options.find(option => option.value === value) ?? null}
        onChange={option => {
          setValue(option?.value ?? "");
          debouncedSetTextPropertyValue(option?.value ?? "");
        }}
        onBlur={handleBlur}
        TextFieldProps={{ InputLabelProps: { shrink: true } }}
      />
    ) : (
      <TextField
        autoFocus
        label={getEnhancedPropertyLabel(property)}
        value={value}
        onChange={event => {
          const value = event.target.value;
          setValue(value);
          debouncedSetTextPropertyValue(value);
        }}
        onBlur={handleBlur}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  return (
    <LabeledValueWithButton
      label={getEnhancedPropertyLabel(property)}
      onClick={() => setIsEditing(true)}
    >
      {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
    </LabeledValueWithButton>
  );
}

function TextComputedPropertyField({
  property,
  readOnly,
  getPropertyDisplayValue,
  ...props
}: ComputedPropertyFieldProps<Props2TextComputed>) {
  if (readOnly) {
    return (
      <LabeledValue
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValue>
    );
  }
  return (
    <ModalOpenButton
      Modal={PropertyExpressionModal}
      modalProps={{
        ...props,
        fieldName: property.key,
        expression: property.expr,
      }}
    >
      <LabeledValueWithButton
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValueWithButton>
    </ModalOpenButton>
  );
}

function TextArrayPropertyField({
  property,
  setPropertyValue,
  readOnly,
  expressionButtonRef,
  isEditing,
  setIsEditing,
  getPropertyDisplayValue,
}: PropertyFieldProps<Props2TextArray>) {
  const latestSavedValue = React.useRef<string[] | null | undefined>(undefined);

  const [value, setValue] = useSyncState<string[] | null>(
    property.valueTextArray ?? null,
    latestSavedValue,
    defaultIsEqualArray
  );

  const setTextArrayPropertyValue = React.useCallback(
    (value: string[] | null) => {
      latestSavedValue.current = value;
      setPropertyValue({
        textArray: {
          key: property.key,
          valueTextArray: value,
        },
      });
    },
    [property.key, setPropertyValue]
  );

  const debouncedSetTextArrayPropertyValue = React.useMemo(
    () => debounce(setTextArrayPropertyValue, 500),
    [setTextArrayPropertyValue]
  );

  const handleBlur = (event: React.FocusEvent) => {
    if (
      (!expressionButtonRef.current ||
        event.relatedTarget !== expressionButtonRef.current) &&
      !isEventRelatedTargetInsideTarget(event.nativeEvent)
    ) {
      setIsEditing(false);
    }
  };

  if (readOnly) {
    return (
      <LabeledValue label={getEnhancedPropertyLabel(property)}>
        {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
      </LabeledValue>
    );
  }

  if (isEditing) {
    return property.allowedValuesText.length > 0 ? (
      <SelectMultiple
        autoFocus
        label={getEnhancedPropertyLabel(property)}
        options={property.allowedValuesText.map(value => ({
          label: value.allowedText,
          value: value.allowedText,
        }))}
        value={value ?? []}
        onChange={value => {
          setValue(value);
          debouncedSetTextArrayPropertyValue(value);
        }}
        onBlur={handleBlur}
      />
    ) : (
      <TextField
        autoFocus
        label={getEnhancedPropertyLabel(property)}
        value={value?.join(",") ?? ""}
        onChange={event => {
          const value = event.target.value;
          setValue(value.split(","));
          debouncedSetTextArrayPropertyValue(value.split(","));
        }}
        onBlur={handleBlur}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  return (
    <LabeledValueWithButton
      label={getEnhancedPropertyLabel(property)}
      onClick={() => setIsEditing(true)}
    >
      {renderValueWithAllowedValues(getPropertyDisplayValue(property))}
    </LabeledValueWithButton>
  );
}

function TextArrayComputedPropertyField({
  property,
  readOnly,
  getPropertyDisplayValue,
  ...props
}: ComputedPropertyFieldProps<Props2TextArrayComputed>) {
  if (readOnly) {
    return (
      <LabeledValue
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValue>
    );
  }
  return (
    <ModalOpenButton
      Modal={PropertyExpressionModal}
      modalProps={{
        ...props,
        fieldName: property.key,
        expression: property.expr,
      }}
    >
      <LabeledValueWithButton
        label={getEnhancedPropertyLabel(property)}
        labelIcon={FunctionsIcon}
      >
        {getPropertyDisplayValue(property).displayedValue}
      </LabeledValueWithButton>
    </ModalOpenButton>
  );
}

const ExpressionButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof IconButton>
>((props, ref) => {
  return (
    <IconButton ref={ref} color="secondary" size="small" {...props}>
      <FunctionsIcon />
    </IconButton>
  );
});

function isEventRelatedTargetInsideTarget(event: FocusEvent) {
  return (event.target as Node)?.contains(event.relatedTarget as Node);
}
