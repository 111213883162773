import { gql } from "@apollo/client";
import { Alert, Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ProjectTicketUnplannedAlertFragment } from "./ProjectTicketUnplannedAlert.generated";

interface Props {
  project: ProjectTicketUnplannedAlertFragment;
}

export const ProjectTicketUnplannedAlert = ({ project }: Props) => {
  const { t } = useTranslate(["ProjectOverview", "Global"]);
  const location = useLocation();

  if (
    project.ticket &&
    !project.planned &&
    ["opportunity", "contracted"].includes(project.state)
  ) {
    return (
      <Alert
        variant="outlined"
        severity="warning"
        action={
          <Button
            size="extra-small"
            color="warning"
            variant="outlined"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.stopPropagation();
            }}
            component={Link}
            to={{
              pathname: `/planning/projects/${project.id}`,
              search: new URLSearchParams({
                r: location.pathname + location.search,
              }).toString(),
            }}
          >
            {t("Schedule ticket work", { ns: "ProjectOverview" })}
          </Button>
        }
      >
        {t("This ticket is unplanned", { ns: "ProjectOverview" })}
      </Alert>
    );
  }

  return null;
};
