import { useApolloClient } from "@apollo/client";
import { LoadingSpinner, Modal, PriceInput } from "@msys/ui";
import { Box, Stack, TextField } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, useFormikContext } from "formik";
import { isEqual } from "lodash";
import debounce from "lodash.debounce";
import differenceWith from "lodash.differencewith";
import React from "react";
import * as Yup from "yup";
import { SapProductKmatVariantInputProp } from "../../../clients/graphqlTypes";
import { WizardPropertyField } from "../doc-items/WizardPropertyField";
import {
  useS4HanaKmatProductActivateVariantMutation,
  useS4HanaKmatProductCreateVariantDraftMutation,
  useS4HanaKmatProductSetItemProductFromSapProductMutation,
  useS4HanaKmatProductSetVariantPropValueMutation,
} from "./S4HanaKmatProductConfigureModal.generated";
import { assertNever } from "@msys/common";
import { Props2NonComputedAllFragment } from "../doc-items/properties.generated";
import { DatePickerField } from "../../commons/form-fields/DatePickerField";
import { TimePickerField } from "../../commons/form-fields/TimePickerField";
import { useSnackbar } from "notistack";

interface Props {
  projectId: string;
  quoteId: string;
  itemId: string;
  productId: string;
  manufacturerArticleNumber: string | null | undefined;
  netPrice: number | null | undefined;
  refetchQueries?: string[];
  handleClose: () => void;
}
export const S4HanaKmatProductConfigModal = ({
  projectId,
  quoteId,
  itemId,
  productId,
  manufacturerArticleNumber: initialManufacturerArticleNumber,
  netPrice: initialNetPrice,
  refetchQueries,
  handleClose,
}: Props) => {
  const { t } = useTranslate(["Global", "SapS4Hana"]);
  const { enqueueSnackbar } = useSnackbar();

  const [netPrice, setNetPrice] = React.useState<number>(initialNetPrice ?? 0);
  const [manufacturerArticleNumber, setManufacturerArticleNumber] =
    React.useState<string>(initialManufacturerArticleNumber ?? "");
  const [properties, setProperties] =
    React.useState<Array<SapProductKmatVariantInputProp> | null>(null);
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isValid, setValidity] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const client = useApolloClient();

  const [createProductDraft, createProductDraftQuery] =
    useS4HanaKmatProductCreateVariantDraftMutation({
      client,
    });
  const productVariantDraft =
    createProductDraftQuery.data?.sapCreateProductKmatVariantDraft;
  React.useEffect(() => {
    createProductDraft({ variables: { input: { productId } } }).then(result => {
      if (!result.data) {
        throw new Error("Failed to create product variant draft");
      }
      setProperties(result.data.sapCreateProductKmatVariantDraft.inputProps);
    });
  }, [createProductDraft, productId]);

  const [setProductVariantPropValue, setProductVariantPropValueMutation] =
    useS4HanaKmatProductSetVariantPropValueMutation({ client });

  const handleChange = React.useCallback(
    async (prop: { key: string; value: string | null }) => {
      setIsUpdating(true);
      try {
        if (!productVariantDraft) {
          throw new Error("Product variant draft missing");
        }
        const result = await setProductVariantPropValue({
          variables: {
            input: {
              baseProductId: productVariantDraft.baseProductId,
              productDraftUuid: productVariantDraft.productDraftUuid,
              prop,
            },
          },
        });

        if (!result.data) {
          throw new Error(" Failed to set prouct variant prop value");
        }

        setProperties(
          result.data.sapSetProductKmatVariantDraftPropValue.inputProps
        );
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      } finally {
        setIsUpdating(false);
      }
    },
    [enqueueSnackbar, productVariantDraft, setProductVariantPropValue]
  );

  const [activateProductVariant] = useS4HanaKmatProductActivateVariantMutation({
    client,
  });

  const [s4HanaKmatProductSetItemProductFromSapProduct] =
    useS4HanaKmatProductSetItemProductFromSapProductMutation({ client });

  const handleSubmit = async (values: unknown) => {
    if (!productVariantDraft) {
      throw new Error("Product variant draft missing");
    }

    const { data } = await activateProductVariant({
      variables: {
        input: {
          baseProductId: productVariantDraft.baseProductId,
          productDraftUuid: productVariantDraft.productDraftUuid,
          netPrice,
          supplierArticleNumber: manufacturerArticleNumber,
        },
      },
    });

    if (!data) {
      throw new Error("Failed to activate product variant");
    }

    await s4HanaKmatProductSetItemProductFromSapProduct({
      variables: {
        input: {
          projectId,
          quoteId,
          itemId,
          sapProductId:
            data.sapCreateProductFromProductKmatVariantDraft.productId,
        },
      },
      refetchQueries,
    });

    handleClose();
  };

  const { initialValues, validationSchema } = React.useMemo(
    () => ({
      initialValues: properties
        ? properties.reduce(
            (acc, property) => ({
              ...acc,
              [property.prop.key]: property.prop,
            }),
            {}
          )
        : {},
      validationSchema: properties
        ? Yup.object().shape(
            properties.reduce(
              (acc, property) => ({
                ...acc,
                [property.prop.key]: Yup.object().shape(
                  property.prop.__typename === "Props2Text"
                    ? {
                        valueText: Yup.string()
                          .nullable()
                          .when([], {
                            is: property.isRequired,
                            then: schema => schema.required(),
                          })
                          .label(property.prop.label),
                      }
                    : property.prop.__typename === "Props2Number"
                      ? {
                          valueNumber: Yup.number()
                            .nullable()
                            .when([], {
                              is: property.isRequired,
                              then: schema => schema.required(),
                            })
                            .label(property.prop.label),
                        }
                      : property.prop.__typename === "Props2Bool"
                        ? {
                            valueBool: Yup.boolean()
                              .nullable()
                              .when([], {
                                is: property.isRequired,
                                then: schema => schema.required(),
                              })
                              .label(property.prop.label),
                          }
                        : property.prop.__typename === "Props2NumberArray"
                          ? {
                              valueNumberArray: Yup.array(Yup.number())
                                .nullable()
                                .when([], {
                                  is: property.isRequired,
                                  then: schema => schema.required(),
                                })
                                .label(property.prop.label),
                            }
                          : property.prop.__typename === "Props2TextArray"
                            ? {
                                valueTextArray: Yup.array(Yup.string())
                                  .nullable()
                                  .when([], {
                                    is: property.isRequired,
                                    then: schema => schema.required(),
                                  })
                                  .label(property.prop.label),
                              }
                            : assertNever(property.prop)
                ),
              }),
              {}
            )
          )
        : undefined,
    }),
    [properties]
  );

  const formId = React.useId();

  return (
    <Modal
      title={t("Configure product", { ns: "SapS4Hana" })}
      handleClose={handleClose}
      isLoading={
        !createProductDraftQuery.called || createProductDraftQuery.loading
      }
      actionButtons={[
        {
          label: t("Submit", { ns: "Global" }),
          buttonProps: {
            type: "submit",
            form: formId,
            disabled:
              isUpdating || !isValid || !manufacturerArticleNumber || !netPrice,
            loading: isSubmitting,
          },
        },
      ]}
    >
      {properties && (
        <Formik
          key={JSON.stringify(properties)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {formikProps => (
            <Form id={formId} style={{ position: "relative" }}>
              <Stack spacing={1}>
                <TextField
                  label={t("Manufacturer article nr", { ns: "SapS4Hana" })}
                  value={manufacturerArticleNumber}
                  onChange={event => {
                    setManufacturerArticleNumber(event.target.value);
                  }}
                />
                <PriceInput
                  label={t("Factory price", { ns: "SapS4Hana" })}
                  value={netPrice}
                  onChange={value => {
                    setNetPrice(value);
                  }}
                />

                {properties.map(property => (
                  <Stack spacing={1}>
                    {property.textPropFormat === "DATE" ? (
                      <DatePickerField
                        name={`${property.prop.key}.valueText`}
                        label={property.prop.label}
                        onChange={(event, value) => {
                          formikProps.setFieldValue(
                            `${property.prop.key}.valueText`,
                            value?.toString()
                          );
                        }}
                      />
                    ) : property.textPropFormat === "TIME" ? (
                      <TimePickerField
                        name={`${property.prop.key}.valueText`}
                        label={property.prop.label}
                      />
                    ) : (
                      <WizardPropertyField
                        key={property.prop.key}
                        name={property.prop.key}
                        property={property.prop}
                        required={property.isRequired}
                        disabled={
                          property.isReadonly ||
                          setProductVariantPropValueMutation.loading
                        }
                        valueLabels={property.valueLabels}
                        showSelectThreshold={0}
                      />
                    )}
                  </Stack>
                ))}
              </Stack>
              {isUpdating && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    pointerEvents: "none",
                    backgroundColor: "white",
                    opacity: 0.7,
                    zIndex: 1,
                  }}
                >
                  <Box sx={{ display: "flex", height: "100%" }}>
                    <LoadingSpinner sx={{ opacity: 1 }} />
                  </Box>
                </Box>
              )}
              <FormikChangeObserver
                handleChange={handleChange}
                setValidity={setValidity}
                setIsSubmitting={setIsSubmitting}
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

const FormikChangeObserver = ({
  handleChange,
  setValidity,
  setIsSubmitting,
}: {
  handleChange: (prop: { key: string; value: string | null }) => Promise<void>;
  setValidity: (isValid: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
}) => {
  const { values, initialValues, isValid, isSubmitting } = useFormikContext<{
    [key: string]: Props2NonComputedAllFragment;
  }>();

  const handleDebouncedChange = React.useMemo(
    () => debounce(handleChange, 1000, { leading: false, trailing: true }),
    [handleChange]
  );

  React.useEffect(() => {
    const diff = differenceWith(
      Object.entries(values),
      Object.entries(initialValues),
      isEqual
    );

    if (diff.length > 0) {
      const prop = diff[0][1];
      handleDebouncedChange({
        key: diff[0][0],
        value:
          prop.__typename === "Props2Text"
            ? prop.valueText ?? null
            : prop.__typename === "Props2Number"
              ? prop.valueNumber?.toString() ?? null
              : prop.__typename === "Props2Bool"
                ? prop.valueBool?.toString() ?? null
                : prop.__typename === "Props2NumberArray"
                  ? prop.valueNumberArray?.join(",") ?? null
                  : prop.__typename === "Props2TextArray"
                    ? prop.valueTextArray?.join(",") ?? null
                    : assertNever(prop),
      });
    }
  }, [handleDebouncedChange, initialValues, values]);

  React.useEffect(() => {
    setValidity(isValid);
  }, [isValid, setValidity]);

  React.useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);

  return null;
};
