import {
  DebouncedSearchInput,
  ListHeader,
  ModalOpenButton,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  OrganisationProjectsSorting,
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig";
import { OpportunityListItemDisplayConfig } from "../../features/opportunities/components/OpportunityListItem";
import {
  Filters,
  OpportunitiesList,
} from "../../features/opportunities/OpportunitiesList";
import { defaultListItemDisplayConfig } from "../../features/opportunities/OpportunitiesListItemVisibilitySettingsModal";
import { FilterRelevantToMe } from "../../features/projects/filters";
import { ProjectsFilterModal } from "../../features/projects/ProjectsFilterModal";
import { renderProjectsFilterChips } from "../../features/projects/renderProjectsFilterChips";
import { PROJECT_FILTER_MAP } from "../../features/projects/useProjectSources";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { useUserPreference } from "../../features/users/useUserPreference";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];
const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function OpportunitiesPast({ submenuItems }: Props) {
  const { t } = useTranslate([
    "Projects",
    "Tickets",
    "Opportunities",
    "Categories",
    "Global",
    "ProjectMembers",
  ]);

  const viewer = useUserData().currentUser!;

  const { getFormattedPrice, getFormattedFloat } = useFormatting();

  const [activeView, setActiveView] =
    useCollectionViewWithMobile<CollectionView>(
      "opportunities-past",
      "table",
      "list"
    );
  const { isMinTablet } = useScreenWidth();

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const baseVariables = React.useMemo(
    () => ({
      offset,
      limit,
      sorting: DEFAULT_SORTING,
    }),
    [limit, offset]
  );

  const allowedStates: ProjectStateMachineStatus[] = React.useMemo(
    () => ["contractor_declined", "contractee_declined"],
    []
  );

  const variables = React.useMemo(
    () => ({
      ...baseVariables,
      ...filters,
      filterBySources: filters.sources
        ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
        : undefined,
      filterIncludeState: filters.filterIncludeState?.length
        ? filters.filterIncludeState
        : allowedStates,
      sorting,
    }),
    [baseVariables, allowedStates, filters, sorting]
  );

  const stateStore = useDataGridStateStore("OpportunitiesPast");
  const listItemDisplayConfigStore =
    useUserPreference<OpportunityListItemDisplayConfig>(
      `DisplayConfig-OpportunitiesCurrent`,
      defaultListItemDisplayConfig
    );

  const onKindChange = (
    value: ProjectFilterKind | null | undefined
  ): Partial<Filters> => ({
    kind: !value ? ["DEFAULT", "TICKET"] : [value],
    filterKind: value,
  });

  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  return (
    <Page
      subtitle={t("Opportunities", { ns: "Opportunities" })}
      title={t("Past", { ns: "Opportunities" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={title}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={ProjectsFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                allowedStates,
                customFieldConfigs,
                onKindChange,
                showPhasesFilter: activeView !== "kanban",
                showSorting: activeView !== "kanban",
                showRelevantToMe: !isMinTablet,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterFields={
            isMinTablet ? (
              <Box display="flex" alignItems="center" height={0} pl={1.5}>
                <FilterRelevantToMe filters={filters} setFilters={setFilters} />
              </Box>
            ) : undefined
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }))
              }
            />
          }
          FilterChips={renderProjectsFilterChips({
            viewer,
            t,
            filters,
            setFilters,
            allowedStates,
            getFormattedPrice,
            getFormattedFloat,
            onKindChange,
            showRelevantToMe: !isMinTablet,
            showPhasesChip: true,
          })}
          mb={2}
        />
        {!listItemDisplayConfigStore.loading && (
          <OpportunitiesList
            sorting={sorting}
            setSorting={setSorting}
            activeView={activeView}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            stateStore={stateStore}
            displayConfig={listItemDisplayConfigStore.value}
            variables={variables}
          />
        )}
      </PageContainer>
    </Page>
  );
}
