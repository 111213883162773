import { assert } from "@msys/common";
import omit from "lodash/omit";
import React from "react";
import { ApplyItemActionInput } from "../../../clients/graphqlTypes";
import { EMBEDDABLE_WIZARD_URL } from "../../../environment";
import { useAuth } from "../../auth/useAuth";
import { useUserData } from "../../auth/useUserData";
import { assertNever } from "../../utils";

export type WizardResults = {
  itemUuidSeed: string;
  answers: Array<ApplyItemActionInput>;
};

export type WizardQuestion = any; // internal structure we pre-save to restore the wizard when necessary

export type WizardApplyItemAction = {
  type: string;
  itemId: string;
  entries: Array<
    | {
        kind: "Props2Bool";
        key: string;
        value: boolean | null;
      }
    | {
        kind: "Props2Number";
        key: string;
        value: number | null;
      }
    | {
        kind: "Props2NumberArray";
        key: string;
        value: number[] | null;
      }
    | {
        kind: "Props2Text";
        key: string;
        value: string | null;
      }
    | {
        kind: "Props2TextArray";
        key: string;
        value: string[] | null;
      }
  >;
};

export type WizardAnswer = {
  question: WizardQuestion;
  applyItemAction: WizardApplyItemAction;
};

export type WizardAnswerResults = {
  itemUuidSeed: string;
  answers: Array<WizardAnswer>;
};

export function QuoteCreateWizardSection({
  organisationId,
  templateId,
  onComplete,
  onAnswer,
  initialAnswers,
}: {
  organisationId: string;
  templateId: string;
  onComplete?: (result: WizardResults) => Promise<void> | void;
  onAnswer?: (result: WizardAnswerResults) => Promise<void> | void;
  initialAnswers?: WizardAnswerResults | null;
}) {
  const viewer = useUserData().currentUser!;
  const { auth } = useAuth();

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = React.useState<number>(384);

  const latest = React.useRef({ onComplete, onAnswer, initialAnswers });
  latest.current = { onComplete, onAnswer, initialAnswers };

  React.useLayoutEffect(() => {
    async function messageListener(event: MessageEvent) {
      if (event.source !== iframeRef.current?.contentWindow) {
        return;
      }

      const data = event.data;

      switch (data.type) {
        case "resize":
          setIframeHeight(data.contentHeight);
          break;
        case "completed":
          await latest.current.onComplete?.({
            itemUuidSeed: data.itemUuidSeed,
            answers: data.answers.map(convertAnswers),
          });
          break;
        case "answered":
          await latest.current.onAnswer?.({
            itemUuidSeed: data.itemUuidSeed,
            answers: data.answers,
          });
          break;
        case "requestAuthHeaders": {
          assert(data.messagePort, `No message port provided`);

          await auth.updateToken(5);

          const port = data.messagePort as MessagePort;
          port.postMessage({
            type: "responseAuthHeaders",
            headers: auth.token
              ? { authorization: `Bearer ${auth.token}` }
              : {},
          });
          port.close();
          break;
        }
        case "requestInitialAnswers": {
          assert(data.messagePort, `No message port provided`);
          const port = data.messagePort as MessagePort;
          port.postMessage({
            type: "responseInitialAnswers",
            answers: latest.current.initialAnswers?.answers ?? null,
            itemUuidSeed: latest.current.initialAnswers?.itemUuidSeed ?? null,
          });
          port.close();
          break;
        }
      }
    }

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [latest, auth]);

  const src = React.useMemo(() => {
    const url = new URL(EMBEDDABLE_WIZARD_URL);
    url.searchParams.set("contractorId", organisationId);
    url.searchParams.set("templateId", templateId);
    url.searchParams.set("wizardContext", "onQuoteCreate");
    url.searchParams.set(
      "showCardImage",
      viewer.organisation.isCraftsmanOrganisation ? "false" : "true"
    );
    if (initialAnswers && initialAnswers.answers.length > 0) {
      url.searchParams.set("requestInitialAnswers", "true");
    }
    return url.toString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, templateId]);

  return (
    <iframe
      ref={iframeRef}
      src={src}
      style={{ border: "none", width: "100%", height: iframeHeight }}
    ></iframe>
  );
}

export const convertAnswers = (
  answer: WizardApplyItemAction
): ApplyItemActionInput => ({
  [answer.type]:
    answer.type === "setEnterPropsValueEntries"
      ? {
          itemId: answer.itemId,
          entries: answer.entries.map(entry =>
            entry.kind === "Props2Bool"
              ? { bool: { key: entry.key, valueBool: entry.value } }
              : entry.kind === "Props2Number"
                ? {
                    number: {
                      key: entry.key,
                      valueNumber: entry.value,
                    },
                  }
                : entry.kind === "Props2NumberArray"
                  ? {
                      numberArray: {
                        key: entry.key,
                        valueNumberArray: entry.value,
                      },
                    }
                  : entry.kind === "Props2Text"
                    ? {
                        text: {
                          key: entry.key,
                          valueText: entry.value,
                        },
                      }
                    : entry.kind === "Props2TextArray"
                      ? {
                          textArray: {
                            key: entry.key,
                            valueTextArray: entry.value,
                          },
                        }
                      : assertNever(entry)
          ),
        }
      : omit(answer, ["type"]),
});
