import { LabeledValue, ValueWithDiff, VisibilityIndicatorIcon } from "@msys/ui";
import { Grid, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { differenceBy, differenceWith, intersectionWith, isNil } from "lodash";
import { Props2NonComputed } from "../../../clients/graphqlTypes";
import { notSetSymbol } from "../doc-items/helpers";
import { getPropValueWithUnit } from "../doc-items/properties";
import { usePropertyHelpers } from "../doc-items/usePropertyHelpers";
import { PimProductAlternativePropertyValueFragment } from "../products/Product.generated";
import {
  OnProductSelectFn,
  SupplierProductPropertyAlternativeSelect,
  useSortedPropertyAlternatives,
} from "../products/SupplierProductAlternativePicker";
import { SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment } from "../products/SupplierProductAlternativePicker.generated";

interface Props {
  properties?: Props2NonComputed[];
  originalProperties?: Props2NonComputed[];
  viewerIsContractor: boolean;
  hideVisibility?: boolean;
  propertyAlternatives?: SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment[];
  onProductSelect?: OnProductSelectFn;
  gridColumnSpacing?: number;
}

export const LabeledProductPropertiesValue = ({
  properties = [],
  originalProperties = [],
  viewerIsContractor,
  hideVisibility,
  propertyAlternatives,
  onProductSelect,
  gridColumnSpacing = 1,
}: Props) => {
  const { t } = useTranslate(["ItemPropertyField", "Global"]);
  const { getUnitLabel, getBoolLabel, getNumberLabel } = usePropertyHelpers();

  const originalPropertiesToDisplayNotExisting = differenceWith(
    originalProperties,
    properties,
    (originalProperty, property) => originalProperty.label === property.label
  );

  const propertyAlternativesToDisplaySameValue = intersectionWith(
    propertyAlternatives,
    properties,
    (propertyAlternative, property) =>
      propertyAlternative.referenceProperty.prop2.label === property.label &&
      propertyAlternativeValueEqualProperty(propertyAlternative.value, property)
  );

  const propertyAlternativesToDisplayDifferentValue = intersectionWith(
    propertyAlternatives,
    properties,
    (propertyAlternative, property) =>
      propertyAlternative.referenceProperty.prop2.label === property.label &&
      !propertyAlternativeValueEqualProperty(
        propertyAlternative.value,
        property
      )
  );

  const {
    sortedPropertyAlternatives:
      sortedPropertyAlternativesToDisplayDifferentValue,
  } = useSortedPropertyAlternatives(
    propertyAlternativesToDisplayDifferentValue
  );

  const propertyAlternativesToDisplayNotExisting = propertyAlternatives
    ? intersectionWith(
        propertyAlternatives,
        originalPropertiesToDisplayNotExisting,
        (propertyAlternative, originalProperty) =>
          propertyAlternative.referenceProperty.prop2.label ===
          originalProperty.label
      )
    : [];

  const propertyAlternativesNotExisting = differenceBy(
    propertyAlternatives,
    propertyAlternativesToDisplaySameValue,
    propertyAlternativesToDisplayDifferentValue,
    propertyAlternativesToDisplayNotExisting,
    p => p.referenceProperty.prop2.label
  );

  if (
    properties.length <= 0 &&
    originalProperties.length <= 0 &&
    viewerIsContractor &&
    propertyAlternativesNotExisting.length <= 0
  ) {
    return (
      <Stack>
        <Typography variant="caption" color="gray">
          {t("Not set", {
            ns: "Global",
          })}
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid
      container
      columns={2}
      rowSpacing={1}
      columnSpacing={gridColumnSpacing}
    >
      {properties.map((property, i) => {
        const propertyValue = getPropValueWithUnit(
          property,
          getUnitLabel,
          getBoolLabel,
          getNumberLabel,
          notSetSymbol
        );

        const propertyAlternative = propertyAlternativesToDisplaySameValue.find(
          a => a.referenceProperty.prop2.label === property.label
        );

        const originalProperty = originalProperties.find(
          originalProperty => originalProperty.label === property.label
        );

        const originalPropertyValue = originalProperty
          ? getPropValueWithUnit(
              originalProperty,
              getUnitLabel,
              getBoolLabel,
              getNumberLabel,
              notSetSymbol
            )
          : null;

        return (
          <Grid item xs={1} key={property.key}>
            <Stack
              direction={"row"}
              spacing={0.5}
              alignItems="center"
              justifyContent={"space-between"}
            >
              {propertyAlternative && onProductSelect ? (
                <SupplierProductPropertyAlternativeSelect
                  propertyAlternative={propertyAlternative}
                  onProductSelect={onProductSelect}
                  getLabel={{ getUnitLabel, getBoolLabel, getNumberLabel }}
                  valueNotSetLabel={t("Not set", { ns: "Global" })}
                />
              ) : (
                <LabeledValue label={property.label}>
                  {originalPropertyValue ? (
                    <ValueWithDiff
                      value={propertyValue}
                      diffValue={
                        viewerIsContractor ? originalPropertyValue : undefined
                      }
                      showDiff={!!originalPropertyValue}
                      spacing={0}
                    />
                  ) : (
                    propertyValue
                  )}
                </LabeledValue>
              )}
              {viewerIsContractor && !hideVisibility && (
                <VisibilityIndicatorIcon
                  isVisible={property.clientVisibility}
                />
              )}
            </Stack>
          </Grid>
        );
      })}
      {viewerIsContractor &&
        onProductSelect &&
        sortedPropertyAlternativesToDisplayDifferentValue.map(
          propertyAlternative => {
            return (
              <Grid
                item
                xs={1}
                key={propertyAlternative.referenceProperty.prop2.key}
              >
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <SupplierProductPropertyAlternativeSelect
                    propertyAlternative={propertyAlternative}
                    onProductSelect={onProductSelect}
                    getLabel={{ getUnitLabel, getBoolLabel, getNumberLabel }}
                    valueNotSetLabel={t("Not set", { ns: "Global" })}
                  />
                  <VisibilityIndicatorIcon isVisible={false} />
                </Stack>
              </Grid>
            );
          }
        )}
      {viewerIsContractor &&
        originalPropertiesToDisplayNotExisting.map((property, i) => {
          const originalPropertyValue = getPropValueWithUnit(
            property,
            getUnitLabel,
            getBoolLabel,
            getNumberLabel,
            notSetSymbol
          );

          const propertyAlternative =
            propertyAlternativesToDisplayNotExisting.find(
              a => a.referenceProperty.prop2.label === property.label
            );

          return (
            <Grid item xs={1} key={property.key}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems="center"
                justifyContent={"space-between"}
              >
                {propertyAlternative && onProductSelect ? (
                  <SupplierProductPropertyAlternativeSelect
                    propertyAlternative={propertyAlternative}
                    onProductSelect={onProductSelect}
                    getLabel={{ getUnitLabel, getBoolLabel, getNumberLabel }}
                    valueNotSetLabel={t("Not set", { ns: "Global" })}
                  />
                ) : (
                  <LabeledValue label={property.label}>
                    <ValueWithDiff
                      value={undefined}
                      diffValue={originalPropertyValue}
                      showDiff={true}
                      spacing={0}
                    />
                  </LabeledValue>
                )}
                <VisibilityIndicatorIcon
                  isVisible={property.clientVisibility}
                />
              </Stack>
            </Grid>
          );
        })}
      {viewerIsContractor &&
        onProductSelect &&
        propertyAlternativesNotExisting.map(propertyAlternative => {
          return (
            <Grid
              item
              xs={1}
              key={propertyAlternative.referenceProperty.prop2.key}
            >
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <SupplierProductPropertyAlternativeSelect
                  propertyAlternative={propertyAlternative}
                  onProductSelect={onProductSelect}
                  getLabel={{
                    getUnitLabel,
                    getBoolLabel,
                    getNumberLabel,
                  }}
                  valueNotSetLabel={t("Not set", { ns: "Global" })}
                />

                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
          );
        })}
    </Grid>
  );
};

function propertyAlternativeValueEqualProperty(
  propertyAlternativeValue:
    | PimProductAlternativePropertyValueFragment
    | null
    | undefined,
  property: Props2NonComputed
): boolean {
  switch (property.__typename) {
    case "Props2Bool": {
      return (
        (isNil(propertyAlternativeValue) && isNil(property.valueBool)) ||
        (propertyAlternativeValue?.__typename ===
          "PimProductAlternativePropertyValueBool" &&
          propertyAlternativeValue.valueBool === property.valueBool)
      );
    }
    case "Props2Number": {
      return (
        (isNil(propertyAlternativeValue) && isNil(property.valueNumber)) ||
        (propertyAlternativeValue?.__typename ===
          "PimProductAlternativePropertyValueNumber" &&
          propertyAlternativeValue.valueNumber === property.valueNumber)
      );
    }
    case "Props2Text": {
      return (
        (isNil(propertyAlternativeValue) && isNil(property.valueText)) ||
        (propertyAlternativeValue?.__typename ===
          "PimProductAlternativePropertyValueText" &&
          propertyAlternativeValue.valueText === property.valueText)
      );
    }
    default: {
      return false;
    }
  }
}
