import { useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull } from "@msys/common";
import { CardContainer, Modal, useScreenWidth } from "@msys/ui";
import { Button, DialogActions, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useProductTypeOverviewModalQuery } from "./ProductTypeOverviewModal.generated";
import { LabeledProductPropertiesValue } from "../../item-properties/LabeledProductPropertiesValue";
import { PimProductTypePropertyTypeFragment } from "../Product.generated";
import { Props2NonComputed } from "../../../../clients/graphqlTypes";

interface Props {
  productTypeId: string;
  headerActions?: React.ReactNode;
  handleClose: () => void;
  dialogActionButton?: React.ReactNode;
  canEdit?: boolean;
}

export const ProductTypeOverviewModal = ({
  productTypeId,
  handleClose,
  headerActions,
  dialogActionButton,
}: Props) => {
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Global"]);

  const client = useApolloClient();
  const query = useProductTypeOverviewModalQuery({
    client,
    variables: {
      id: productTypeId,
    },
  });

  const productType = getDataOrNull(query?.data?.pimProductType);

  const propertyTypesAsProps = React.useMemo(() => {
    return productType?.propertyTypes?.map(propertyType2Prop) ?? [];
  }, [productType]);

  return (
    <Modal
      title={productType?.label}
      handleClose={handleClose}
      maxWidth="md"
      isLoading={query.loading}
      headerActions={headerActions}
      dialogActions={
        <DialogActions>
          <Stack
            direction={isMinTablet ? "row" : "column"}
            spacing={2}
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              alignSelf={isMinTablet ? undefined : "flex-end"}
            >
              <Button onClick={handleClose} color="primary" variant="text">
                {t("Close", {
                  ns: "Global",
                })}
              </Button>
              {dialogActionButton}
            </Stack>
          </Stack>
        </DialogActions>
      }
      notInStack
    >
      {productType && (
        <CardContainer
          title={t("Properties", {
            ns: "Global",
          })}
          isExpandable
          isInitiallyClosed={false}
          itemCount={productType.propertyTypes.length}
        >
          <Stack direction="column" p={1} spacing={1}>
            <LabeledProductPropertiesValue
              properties={propertyTypesAsProps}
              viewerIsContractor={true}
            />
          </Stack>
        </CardContainer>
      )}
    </Modal>
  );
};

function propertyType2Prop(
  propertyType: PimProductTypePropertyTypeFragment
): Props2NonComputed {
  switch (propertyType.propertyType.kind) {
    case "bool": {
      return {
        __typename: "Props2Bool",
        askWhen: [],
        askWhom: [],
        clientVisibility: false,
        essential: false,
        group: "",
        key: propertyType.propertyType.key,
        label: propertyType.propertyType.label,
        prompt: "",
        valueBool: null,
      };
    }
    case "number": {
      return {
        __typename: "Props2Number",
        askWhen: [],
        askWhom: [],
        clientVisibility: false,
        essential: false,
        group: "",
        key: propertyType.propertyType.key,
        label: propertyType.propertyType.label,
        prompt: "",
        valueNumber: null,
        allowedValuesNumber: [],
      };
    }
    case "text": {
      return {
        __typename: "Props2Text",
        askWhen: [],
        askWhom: [],
        clientVisibility: false,
        essential: false,
        group: "",
        key: propertyType.propertyType.key,
        label: propertyType.propertyType.label,
        prompt: "",
        valueText: null,
        allowedValuesText: propertyType.propertyTypeValues.map(v => ({
          __typename: "Props2AllowedValuesText",
          allowedText: v.label,
        })),
      };
    }
    case "range": {
      return {
        __typename: "Props2Number",
        askWhen: [],
        askWhom: [],
        clientVisibility: false,
        essential: false,
        group: "",
        key: propertyType.propertyType.key,
        label: propertyType.propertyType.label,
        prompt: "",
        valueNumber: null,
        allowedValuesNumber: [],
      };
    }
    default:
      assertNever(propertyType.propertyType.kind);
  }
}
