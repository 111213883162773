import { CardContainer, CollapseSection } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { ManualSave } from "../../../commons/form-fields/ManualSave";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import {
  useVisibilityStore,
  VisibilityStore,
} from "../../users/useVisibilityStore";

export type ImportVisibilityKeys = "gaeb" | "xiopd";

interface Props {}

export function OrganisationProjectImportSettingsBox(props: Props) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const projectsImportVisibilityStore =
    useVisibilityStore<ImportVisibilityKeys>("ProjectsCurrent-Import");
  const opportunitiesImportVisibilityStore =
    useVisibilityStore<ImportVisibilityKeys>("OpportunitiesCurrent-Import");

  if (
    projectsImportVisibilityStore.loading ||
    opportunitiesImportVisibilityStore.loading
  )
    return null;

  return (
    <CardContainer
      isExpandable
      title={t("Projects import settings", { ns: "OrganisationSettings" })}
    >
      <OrganisationProjectImportSettingsForm
        projectsImportVisibilityStore={projectsImportVisibilityStore}
        opportunitiesImportVisibilityStore={opportunitiesImportVisibilityStore}
        {...props}
        labels={{
          xiopd: t("{fileFormat} files", {
            ns: "Global",
            fileFormat: "xi:opd",
          }),
          gaeb: t("{fileFormat} files", {
            ns: "Global",
            fileFormat: "GAEB",
          }),
        }}
      />
    </CardContainer>
  );
}

interface FormValues<Keys extends string> {
  projectsImportVisibility: Record<Keys, boolean>;
  opportunitiesImportVisibility: Record<Keys, boolean>;
}

function OrganisationProjectImportSettingsForm<Keys extends string>({
  projectsImportVisibilityStore,
  opportunitiesImportVisibilityStore,
  labels,
}: {
  projectsImportVisibilityStore: VisibilityStore<Keys>;
  opportunitiesImportVisibilityStore: VisibilityStore<Keys>;
  labels: Record<Keys, string>;
}) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate([
    "OrganisationSettings",
    "Projects",
    "Opportunities",
  ]);

  const initialValues: FormValues<Keys> = React.useMemo(
    () => ({
      projectsImportVisibility: Object.fromEntries(
        (Object.keys(labels) as Keys[]).map(field => [
          field,
          projectsImportVisibilityStore.value[field] ?? true,
        ])
      ) as Record<Keys, boolean>,
      opportunitiesImportVisibility: Object.fromEntries(
        (Object.keys(labels) as Keys[]).map(field => [
          field,
          opportunitiesImportVisibilityStore.value[field] ?? true,
        ])
      ) as Record<Keys, boolean>,
    }),
    [
      labels,
      projectsImportVisibilityStore.value,
      opportunitiesImportVisibilityStore.value,
    ]
  );

  const validationSchema = Yup.object().shape({
    projectsImportVisibility: Yup.object()
      .shape(
        Object.fromEntries(
          (Object.keys(labels) as Keys[]).map(field => [
            field,
            Yup.boolean().label(labels[field]).required(),
          ])
        )
      )
      .required(),
    opportunitiesImportVisibility: Yup.object()
      .shape(
        Object.fromEntries(
          (Object.keys(labels) as Keys[]).map(field => [
            field,
            Yup.boolean().label(labels[field]).required(),
          ])
        )
      )
      .required(),
  });

  const handleSubmit = async (values: FormValues<Keys>) => {
    projectsImportVisibilityStore.saveValue(values.projectsImportVisibility);
    opportunitiesImportVisibilityStore.saveValue(
      values.opportunitiesImportVisibility
    );
  };

  return (
    <Formik<FormValues<Keys>>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues<Keys>>) => (
        <Form>
          <Stack direction="column" spacing={1} p={1}>
            <CollapseSection
              title={t("Import projects via", { ns: "Projects" })}
            >
              <Stack direction="column" spacing={0}>
                {(Object.entries(labels) as [Keys, string][]).map(
                  ([field, label]) => (
                    <SwitchField
                      key={field}
                      label={label}
                      name={`projectsImportVisibility.${field}`}
                      disabled={formikProps.isSubmitting}
                    />
                  )
                )}
              </Stack>
            </CollapseSection>
            {viewer.organisation.isCraftsmanOrganisation && (
              <CollapseSection
                title={t("Import opportunities via", { ns: "Opportunities" })}
              >
                <Stack direction="column" spacing={0}>
                  {(Object.entries(labels) as [Keys, string][]).map(
                    ([field, label]) => (
                      <SwitchField
                        key={field}
                        label={label}
                        name={`opportunitiesImportVisibility.${field}`}
                        disabled={formikProps.isSubmitting}
                      />
                    )
                  )}
                </Stack>
              </CollapseSection>
            )}
            <ManualSave
              onCancel={formikProps.handleReset}
              disabled={formikProps.isSubmitting}
              disabledCancel={!formikProps.dirty}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
